import { Product } from "@/interfaces/product";
import { BrandProductsTableMetadata } from "@/metadata/brands";
import { brandSubTableService } from "@/services/brandSubTables.service";
import { Callback } from "@/types/types";
import {
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./ProductsAssigned.template.vue";

const namespace = "BrandModule";
@Component({
  mixins: [Template],
  components: {
    TableComponent
  }
})
export class ProductsAssignedComponent extends Vue {
  @Prop() public brandId!: string;
  @Prop({ default: false }) public onDisplay!: boolean;
  @Getter("products", { namespace }) public products!: Product[];
  @Getter("productsPagination", { namespace })
  public pagination!: TablePagination;
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Action("loadProducts", { namespace }) public productsAction!: Callback;

  public headers: TableHeader[] = BrandProductsTableMetadata;
  public rowActions: TableAction[] = brandSubTableService.getProductsRowActions();
  public paginate = brandSubTableService.paginationAction();

  public changePagination(pagination: TablePagination) {
    this.paginate({
      currentPage: pagination.currentPage,
      itemsPerPage: pagination.itemsPerPage,
      dispatchAction: `${namespace}/loadProducts`
    });
  }

  protected mounted() {
    const unwatch = this.$watch("onDisplay", () => {
      if (this.onDisplay) {
        this.productsAction(this.brandId);
        unwatch();
      }
    });

    this.headers.map(h => {
      h.label = this.$t(h.label).toString();
      return h;
    });
  }
}
