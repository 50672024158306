import { WmBrandModel } from "@/interfaces/brandWeedmaps";
import { weedmapsInfoService } from "@/services/weedmapsInfo.service";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./WeedmapsInfo.template.vue";

@Component({
  mixins: [Template]
})
export default class WeedmapsInfoComponent extends Vue {
  @Prop({ default: null }) public brandId!: string;
  @Prop({ default: false }) public onDisplay!: boolean;
  public disabled = false;
  public brandWeedmaps: WmBrandModel[] = [];
  public searchBrandweedmaps: string | null = null;
  public currentWeedmapsBrand: null | string = null;
  public defaultBrands: WmBrandModel[] = [];
  public loadingBrands = false;
  public debounceSearch = debounce(async (name: string) => {
    if ((name && name.length > 2) || !name) {
      this.getWeedmapsBrands(name);
    }
  }, 500);

  @Watch("searchBrandweedmaps")
  public async searchData(name: string) {
    if (!this.currentWeedmapsBrand) {
      this.debounceSearch(name);
    }
  }

  public async mounted() {
    const unwatch = this.$watch("onDisplay", async () => {
      if (this.onDisplay) {
        if (this.brandId) {
          this.loadingBrands = true;
          const currentWeedmapsBrand = await weedmapsInfoService.getBrand(
            this.brandId
          );
          if (currentWeedmapsBrand && currentWeedmapsBrand.is_master) {
            this.currentWeedmapsBrand = currentWeedmapsBrand.weedmaps_brand_id;
            this.brandWeedmaps = [currentWeedmapsBrand];
            this.disabled = true;
          }
          this.loadingBrands = false;
        }

        if (!this.disabled) {
          await this.getWeedmapsBrands();
          this.defaultBrands = cloneDeep(this.brandWeedmaps);
        }
        unwatch();
      }
    });
  }

  public getName(item: any) {
    return item.weedmaps_name || item.name;
  }

  public changeBrands(event: WmBrandModel) {
    this.$emit("changeBrands", {
      biotrack_brand_id: null,
      weedmaps_brand_id: event.weedmaps_brand_id,
      name: event.name
    });
  }

  public async getWeedmapsBrands(name?: string) {
    if (!name && this.defaultBrands.length) {
      this.brandWeedmaps = this.defaultBrands;
      return;
    }
    this.loadingBrands = true;
    this.brandWeedmaps = await weedmapsInfoService.getWeedmapsBrands(name);
    this.loadingBrands = false;
  }
}
