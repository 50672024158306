import { Batch } from "@/interfaces/batch";
import { BrandBatchesTableMetadata } from "@/metadata/brands";
import { brandSubTableService } from "@/services/brandSubTables.service";
import { Callback } from "@/types/types";
import {
  ActionsSubheaderComponent,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./BatchesAssigned.template.vue";

const namespace = "BrandModule";
@Component({
  mixins: [Template],
  components: {
    ActionsSubheaderComponent,
    TableComponent
  }
})
export class BatchesAssignedComponent extends Vue {
  @Prop() public brandId!: string;
  @Prop({ default: false }) public onDisplay!: boolean;
  @Getter("batches", { namespace }) public batches!: Batch[];
  @Getter("batchesPagination", { namespace })
  public pagination!: TablePagination;
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Action("loadBatches", { namespace }) public batchesAction!: Callback;
  public zeroOnly = false;

  public headers: TableHeader[] = BrandBatchesTableMetadata;
  public rowActions: TableAction[] = brandSubTableService.getBatchesRowActions();
  public generalActions: TableAction[] = brandSubTableService.getBatchesGeneralActions();
  public paginate = brandSubTableService.paginationAction();

  public zeroHandler() {
    this.batchesAction({ id: this.brandId, zeroOnly: this.zeroOnly });
  }

  public changePagination(pagination: TablePagination) {
    this.paginate({
      itemsPerPage: pagination.itemsPerPage,
      currentPage: pagination.currentPage
    });
  }

  protected mounted() {
    const unwatch = this.$watch("onDisplay", () => {
      if (this.onDisplay) {
        this.batchesAction({ id: this.brandId });
        unwatch();
      }
    });
    this.headers.map(h => {
      h.label = this.$t(h.label).toString();
      return h;
    });
  }
}
